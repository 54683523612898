import axios from 'axios'

const apiUrl =
  process.env.NODE_ENV === 'production'
    ? 'https://cms.acto.net.br/back/'
    : 'http://localhost:1333/'

export const api = axios.create({
  baseURL: apiUrl,
  headers: {
    'Content-type': 'application/json',
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*'
  }
})
