import { AiOutlineHome } from 'react-icons/ai'
import { FiSettings } from 'react-icons/fi'
import { IoLogOutOutline } from 'react-icons/io5'
import { useNavigate, useLocation } from 'react-router-dom'

import {
  Container,
  Flex,
  Box,
  Center,
  Text,
  Img,
  Circle,
  List,
  ListItem,
  Button,
  Icon,
  IconButton
} from '@chakra-ui/react'

import {
  Profile,
  Content,
  SocialMedia,
  Calendar,
  Library,
  Reports
} from '../../components/Icons'
import { useAuth } from '../../contexts/AuthContext'
import { useState } from 'react'
import { ModalLogout } from './modal'

const menu = [
  {
    label: 'Perfil',
    icon: (color: string) => <Profile stroke={color} fontSize="28px" />,
    path: '/cms/profile'
  },
  {
    label: 'Publicar conteúdo',
    icon: (color: string) => <Content stroke={color} fontSize="28px" />,
    path: '/cms/content'
  },
  {
    label: 'Mídias Sociais',
    icon: (color: string) => <SocialMedia stroke={color} fontSize="28px" />,
    path: '/cms/social-media'
  },
  {
    label: 'Calendário',
    icon: (color: string) => <Calendar stroke={color} fontSize="28px" />,
    path: '/cms/calendar'
  },
  {
    label: 'Biblioteca',
    icon: (color: string) => <Library stroke={color} fontSize="28px" />,
    path: '/cms/library'
  },
  {
    label: 'Relatórios',
    icon: (color: string) => <Reports stroke={color} fontSize="28px" />,
    path: '/cms/reports'
  },
  {
    label: 'Configurações',
    icon: (color: string) => <FiSettings color={color} fontSize="28px" />,
    path: '/cms/settings'
  },
  {
    label: 'Sair',
    icon: () => <IoLogOutOutline color="#1789FC" fontSize="28px" />,
    path: 'logout'
  }
];

const Sidebar = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPath, setCurrentPath] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const { logout } = useAuth();

  const handleLogoutClick = () => {
    setCurrentPath('logout');
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleLogoutConfirm = () => {
    logout();
    setIsModalOpen(false);
  };
  
  const { profileName } = useAuth();

  return (
    <Container p="0" bgColor="#fff" borderRight="1px solid #EAEDF3">
      <Flex flexDir="column">
        <Center color="#000">
          <IconButton bgColor="#fff" aria-label="Home">
            <Icon as={AiOutlineHome} fontSize="24px" color="#1789FC" />
          </IconButton>
        </Center>

        <Box p="24px 57px">
          <Flex flexDir="column" justifyContent="center" alignItems="center">
            <Circle size="90px" color="grey">
              <Img src="" />
            </Circle>
            <Text
              color="#3E3F42"
              fontSize="12px"
              fontWeight="700"
              marginBottom="4px"
            >
              {profileName}
            </Text>

            <Text
              color="#9EA0A5"
              fontSize="12px"
              fontWeight="400"
              marginBottom="4px"
            >
              Prefeito
            </Text>
          </Flex>
        </Box>
        <Box>
          <List>
            {menu.map((m, index: number) => (
              <ListItem
                borderTop="1px solid #EAEDF3"
                display="flex"
                flexDirection="row"
                key={index}
                // p="16px 36px"
                bgColor={location.pathname === m.path ? '#1789FC' : '#fff'}
                color={location.pathname === m.path ? '#fff' : '#3E3F42'}
              >
                <Button
                  onClick={() =>
                    m.path === 'logout' ? handleLogoutClick() : navigate(m.path)
                  }
                  bgColor={location.pathname === m.path ? '#1789FC' : '#fff'}
                  color={location.pathname === m.path ? '#fff' : '#3E3F42'}
                  w="100%"
                  m="10px 0 10px 36px"
                  justifyContent="start"
                  _hover={{
                    backgroundColor:
                      location.pathname === m.path ? '#1789FC' : '#fff'
                  }}
                  _active={{
                    backgroundColor:
                      location.pathname === m.path ? '#1789FC' : '#fff'
                  }}
                  _focus={{
                    border: 'none',
                    outline: 0
                  }}
                >
                  {m.icon(location.pathname === m.path ? '#fff' : '#1789FC')}
                  <Text
                    marginLeft="16px"
                    fontSize="12px"
                    color={location.pathname === m.path ? '#fff' : '#3E3F42'}
                    fontWeight="700"
                  >
                    {m.label}
                  </Text>
                </Button>
              </ListItem>
            ))}

            <ModalLogout
              isOpen={isModalOpen}
              onClose={handleModalClose}
              onConfirm={handleLogoutConfirm}
              confirmText="Sim, desejo sair"
              cancelText="Não, desejo permanecer" 
              title="Deseja sair da aplicação?" 
            >
            </ModalLogout>

          </List>
        </Box>
      </Flex>
    </Container>
  )
}

export default Sidebar
