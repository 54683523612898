import { Link, useLocation } from 'react-router-dom'

import { Breadcrumb, BreadcrumbItem } from '@chakra-ui/react'

const convertBreadCrumbNameFromPath = (key: string) =>
  ({
    settings: 'Configurações',
    users: 'Gerenciar Usuários',
    new: 'Criar Novo Usuário',
    create: 'Incluir novo Usuário',
    manage: 'Gerenciar Páginas',
    action: 'Ações',
    add: 'Incluir Nova Página',
    content: 'Publicar conteúdo',
    banner: 'Criar Banner',
    bannerEdit: 'Editar Banner',
    comunication: 'Serviço de Comunicação'
  }[key])

const SettingsBreadcrumb = () => {
  const { pathname } = useLocation()
  const paths = pathname.split('/').filter(Boolean)
  const isContentPage = paths.includes('content')
  //console.log(paths, "paths");

  return (
    <Breadcrumb spacing="8px" separator=">">
      {!isContentPage && (
        <BreadcrumbItem>
          <Link to="/cms/settings">
            {convertBreadCrumbNameFromPath('settings')}
          </Link>
        </BreadcrumbItem>
      )}
      {paths.slice(1).map((p, index) => {
        const pageName = convertBreadCrumbNameFromPath(p)
        if (!pageName) return null 

        const separator = index !== paths.length - 2 ? '>' : undefined

        return (
          <BreadcrumbItem key={index + 1} separator={separator}>
            <Link to={`/${paths.slice(0, index + 2).join('/')}`}>
              {pageName}
            </Link>
          </BreadcrumbItem>
        )
      })}
    </Breadcrumb>
  )
}

export default SettingsBreadcrumb
